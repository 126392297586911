function initProductCollapse() {

  const collapse = document.querySelectorAll('.single-product__details__expand__head')

  if (collapse.length) {
    for (let item of collapse) {
      item.addEventListener('click', (e) => {
          item.parentElement.classList.toggle('single-product__details--open');
          window.lazy.revalidate();
      })
    }
  }

  const read_more = document.getElementById('excerpt_read_more');
  if ( read_more ) {
    read_more.addEventListener( 'click', function (e) {
      const description = document.getElementById('product_description');
      const panel = description.parentNode;
      if ( panel && !panel.classList.contains('open') ) {
        panel.classList.toggle('open');
        window.lazy.revalidate();
      }
    });
  }

}

initProductCollapse();

