const toggle = document.querySelector(".header__right-nav__burger");
const nav = document.querySelector(".header__center-nav");
const header = document.querySelector('.header')

toggle.addEventListener("click", () => {
  nav.classList.toggle("header__center-nav--mobile-open");
  toggle.classList.toggle("header__right-nav__burger--active");
  header.classList.toggle('nav-open');
});


(function($) {

    $(document).on('click', '.header__center-nav__ul .menu-item-has-children', function(e) {
      if ( window.innerWidth <= 1023 ) {
        // e.preventDefault();
        // We use pointer-events: none; CSS instead for accurate event bubbling.
        const open = $( ".header__center-nav__ul .mobile-open" );
        const wasOpen = $(this).hasClass('mobile-open');
        open.removeClass('mobile-open');
        if ( ! wasOpen ) {
          $(this).addClass('mobile-open');
        }
      }
    });

    $(document).on('click', '.header__center-nav', function(e) {
      if ( window.innerWidth <= 1023 ) {
        const ul = $('.header__center-nav__ul')[0];
        const el = $(this)[0];
        if ( ul && el && el.contains( e.target ) && ! ul.contains( e.target ) ) {
          nav.classList.toggle("header__center-nav--mobile-open");
          toggle.classList.toggle("header__right-nav__burger--active");
          header.classList.toggle('nav-open');
        }
      }
    });
    

})(jQuery);

function debounce(func, wait, immediate) {
  var timeout;
  return function() {
      var context = this, args = arguments;
      var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
  };
};

function scrollDetect(){
  var header = document.getElementById('header');
  var headerHeight = header.getBoundingClientRect().height;
  var lastScroll = 0;

  onScroll();
  window.addEventListener('scroll', debounce( onScroll, 50 ), false);

  function onScroll() {
      let currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value
      if (currentScroll > 0 && lastScroll <= currentScroll){
        if ( currentScroll >= 150 ) {
          header.classList.add('scrolled');
        }
      } else if ( header.classList.contains('scrolled') && ( currentScroll + headerHeight <= 200 ) ){
          header.classList.remove('scrolled');
      }
      lastScroll = currentScroll;
  };
}

scrollDetect();