const faqs = document.querySelectorAll('.faqs__faq')

if (faqs.length) {
  for (let faq of faqs) {
    faq.addEventListener('click', () => {
      faq.classList.toggle('faqs__faq--open');
      window.lazy.revalidate();
    })
  }
}
