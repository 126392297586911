(function($) {

  $(document).on('click', '[data-product_tab]', (function(e) {
    e.preventDefault();
    var isProductPage = false;
    var num = $(this).data("product_tab");
    var tabs = $(this).closest('.product-atc').find('[data-product_tab="' + num + '"]');
    var panel = $(this).closest('.product-atc').find('[data-product_panel="' + num + '"]');
    if ( $(this).closest('.product-atc').is('body') ) {
      isProductPage = true;
      tabs = $(this).closest('.product-atc').find('.single-atc [data-product_tab="' + num + '"]');
      panel = $(this).closest('.product-atc').find('.single-atc [data-product_panel="' + num + '"]');
    }
    var atcbar_html = '';
    if ( isProductPage ) {
      atcbar_html = $(this).html();
    }
    if ( panel.length > 0 ) {
      if ( isProductPage ) {
        $(this).closest('.product-atc').find('.single-atc [data-product_panel]').removeClass("active");
        $(this).closest('.product-atc').find('.single-atc [data-product_tab]').removeClass("active");
        $(this).closest('.product-atc').find('.atcb [data-product_panel]').removeClass("active");
        $(this).closest('.product-atc').find('.atcb [data-product_tab]').removeClass("active");

        $(this).closest('.product-atc').find('.atcb [data-product_tab="' + num + '"]').addClass("active");
        $(this).closest('.product-atc').find('.atcb [data-product_panel="' + num + '"]').addClass("active");
        $("#atcbsp").html(atcbar_html);
      } else {
        $(this).closest('.product-atc').find('[data-product_panel]').removeClass("active");
        $(this).closest('.product-atc').find('[data-product_tab]').removeClass("active");
      }
      panel.addClass("active");
      tabs.addClass("active");
      if ( panel.parents(".nv-product").length ) {
        var a = $(this).attr("href");
        $(this).parents(".product-atc").find(".product-loop__product__link, .nv-product .buy-btn").attr("href", a)
      }
      return false;
    }
  }));

  $(document).on('ready', function() {
    var activeOpt = $('.buy-btn.active');
    if ( activeOpt.length > 0 ) {
      var atcbar_html = $(activeOpt).html();
      if ( atcbar_html ) {
        $("#atcbsp").html(atcbar_html);
      }
    }
  });

  $(document).on( "click", ".wc-quantity-wrapper button.plus, .wc-quantity-wrapper button.minus", ( function() {
    var qty = $(this).closest(".wc-quantity-wrapper").find(".qty");
    var currentQty = parseFloat(qty.val());
    var max = parseFloat(qty.attr("max"));
    var min = parseFloat(qty.attr("min"));
    var step = parseFloat(qty.attr("step"));
    $(this).is(".plus") ? max && max <= currentQty ? qty.val(max) : qty.val(currentQty + step) : min && min >= currentQty ? qty.val(min) : currentQty > 1 && qty.val(currentQty - step),
    $(".input-text.qty.text").change()
  } ) );


})(jQuery);