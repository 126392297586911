const searchBox = document.getElementById("search-box");
const searchToggle = document.getElementById("search-toggle");

searchToggle.addEventListener("click", () => {
  searchBox.classList.toggle("header__search--visible");
  searchBox.querySelector("input").focus();
});

document.onkeypress = function(e) {
  if ((e.keyCode ? e.keyCode : e.charCode) !== 47) return;
  e.preventDefault();
  searchBox.classList.add("header__search--visible");
  searchBox.querySelector("input").focus();
};
