let modalEls = document.querySelectorAll(".modal-el");

function initModals() {
  if (!modalEls) return;

  for (let modalEl of modalEls) {
    let modalOpen = modalEl.querySelector(".modal-open");

    if (!modalOpen) continue;

    modalOpen.addEventListener("click", () => {
      modalEl.querySelector(".modal").classList.toggle("active");
    });

    modalEl.querySelector(".modal-close").addEventListener("click", () => {
      modalEl.querySelector(".modal").classList.remove("active");
    });
  }
}

initModals();
