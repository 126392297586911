function initAccordions() {
  const accordions = document.querySelectorAll('.accordion');

  // Return if no accorions on page
  if (!accordions) return;

  // Loop each accordion section
  accordions.forEach(accordion =>
    // Loop each item in section
    accordion.querySelectorAll('.accordion__item').forEach(accordionItem =>
      // Add click listener to title
      accordionItem
        .querySelector('.accordion__item__title')
        .addEventListener('click', () => {
          // Toggle item active class
          accordionItem.classList.toggle('active');
        }),
    ),
  );
}

initAccordions();
