import Swiper, { Navigation, Pagination, EffectFade, Autoplay, Mousewheel } from 'swiper';

(function($) {
  if ( $('.strapline-swiper').length !== 0 ) {
    // DOM ready, take it away

		const swiper = new Swiper('.strapline-swiper', {
			// Optional parameters
			modules: [ EffectFade, Autoplay ],
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			slidesPerView: 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
		});
  }

  if ( $('.hero-carousel').length !== 0 ) {
    // DOM ready, take it away

    const swiper = new Swiper('.hero-carousel', {
      // Optional parameters
      modules: [ Navigation, Pagination, Autoplay ],
      direction: 'horizontal',
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 7000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
  
      // If we need pagination
      pagination: {
        el: '.slider-controls-dots',
        clickable: true,
        bulletActiveClass: 'active',
        bulletClass: 'slider-dot',
        renderBullet: function (index, className) {
          return '<button aria-label="Button" role="button" class="' + className + '"></button>';
        },
      },
      // Disable preloading of all images
      preloadImages: false,
      
      on: {
        realIndexChange: function() {
          var contrast = this.slides[ this.activeIndex ].getAttribute('data-contrast');
          var carousel = document.getElementById('home-carousel');
          if ( carousel && contrast ) {
            carousel.classList.remove('light');
            carousel.classList.remove('dark');
            carousel.classList.add(contrast);
          }
        }
      }
    });
  }

  if ( $('.image-slider-swiper').length !== 0 ) {
    // DOM ready, take it away

    const imageSlider = new Swiper('.image-slider-swiper', {
      // Optional parameters
      modules: [ Navigation, Pagination, Autoplay ],
      direction: 'horizontal',
      loop: true,
      slidesPerView: 1,
      spaceBetween: 16,
      breakpoints: {
				768: {
					slidesPerView: 1.3,
					spaceBetween: 16,
				},
			},
      centeredSlides: true,
      // autoplay: {
      //   delay: 7000,
      //   disableOnInteraction: false,
      //   pauseOnMouseEnter: true,
      // },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // If we need pagination
      pagination: {
        el: '.slider-pagination',
        clickable: true,
        bulletActiveClass: 'active',
        bulletClass: 'slider-dot',
      },
      // Disable preloading of all images
      preloadImages: false,
    });
  }


	if( $('.progress-swiper').length !== 0 ) {
    const progressSwiper = new Swiper('.progress-swiper', {
      // Optional parameters
      modules: [ Pagination, Mousewheel ],
      direction: 'horizontal',
			grabCursor: true,
			autoplay: false,
      mousewheel: {
				forceToAxis: true,
			},
      loop: false,
      slidesPerView: 1,
			spaceBetween: 8,
			breakpoints: {
				375: {
					slidesPerView: 2,
					spaceBetween: 16,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 32,
				}
			},
      // Disable preloading of all images
      preloadImages: false,
			pagination: {
				el: ".swiper-pagination",
				type: "progressbar",
			},
    });
	}



	if( $('.recommended-products-swiper').length !== 0 ) {
    const progressSwiper = new Swiper('.recommended-products-swiper', {
      // Optional parameters
      modules: [ Pagination, Mousewheel ],
      direction: 'horizontal',
			grabCursor: true,
			autoplay: false,
      mousewheel: {
				forceToAxis: true,
			},
      loop: false,
      slidesPerView: 1,
			spaceBetween: 8,
			breakpoints: {
				768: {
					slidesPerView: 2,
					spaceBetween: 16,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 32,
				}
			},
      // Disable preloading of all images
      preloadImages: false,
			pagination: {
				el: ".swiper-pagination",
				type: "progressbar",
			},
    });
	}


	if ( $('.image-content-slider-init').length !== 0 ) {
    // DOM ready, take it away
    const imageContentSlider = new Swiper('.image-content-slider-init', {
      // Optional parameters
      modules: [ Navigation, Pagination, Autoplay ],
      direction: 'horizontal',
      slidesPerView: 1,
			spaceBetween: 32,
			grabCursor: true,
      // If we need pagination
      pagination: {
        el: '.slider-pagination',
        clickable: true,
        bulletActiveClass: 'active',
        bulletClass: 'slider-dot',
        renderBullet: function (index, className) {
          return '<button aria-label="Button" role="button" class="' + className + '"></button>';
        },
      },
    });
  }

  if ( $('.swiper-scroller').length !== 0 ) {
    new Swiper( '.swiper-scroller',
    {
      modules: [ Autoplay ],
      slidesPerView: 'auto',
      spaceBetween: 0,
			rewind: true,
      speed: 5000,
      preloadImages: true,
      watchSlidesProgress: true,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
    });
  }


  if( $('.swiper-product-reviews').length !== 0 && $('.single-product__reviews__list').length !== 0 ) {
    const productReviewsSwiper = new Swiper('.swiper-product-reviews', {
      // Optional parameters
      modules: [ Pagination, Mousewheel ],
      direction: 'horizontal',
			grabCursor: true,
      mousewheel: {
				forceToAxis: true,
			},
			autoplay: false,
      loop: false,
      slidesPerView: 1,
			spaceBetween: 8,
			breakpoints: {
				768: {
					slidesPerView: 2,
					spaceBetween: 16,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 32,
				}
			},
    });
	}


  const productSwiper = new Swiper('.product-gallery', {
    // Optional parameters
    modules: [ EffectFade, Navigation ],
    direction: 'horizontal',
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.slider-controls-next',
      prevEl: '.slider-controls-prev',
    },
  });

  productSwiper.on('transitionStart', function (s) {
    var videos = s.el.querySelectorAll('video');
    videos.forEach( function( video ){
      video.pause();
    });
  });
  
  productSwiper.on('transitionEnd', function (s ) {    
    var activeIndex = s.activeIndex;
    var activeSlide = s.slides[activeIndex];
    var video = activeSlide.getElementsByTagName('video')[0];

    if ( video ) {
      if ( video.classList.contains( 'lazyvideo' ) ) {
        for (var source in video.children) {
          var videoSource = video.children[source];
          if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
            videoSource.src = videoSource.dataset.src;
          }
        }
        video.load();
        video.classList.remove("lazyvideo");
      } else {
        video.play();
      }
    }
  
  });

  productSwiper.on('realIndexChange', function () {
    var index = productSwiper.realIndex;
    var thumbs = $('[data-thumb]');
    var thumb = $(`[data-thumb='${index}']`);
    thumbs.removeClass('active');
    thumb.addClass('active');
  })

  productSwiper.on('autoplayPause', function () {
    $('.slider-controls-dots').addClass('paused');
  } );

  productSwiper.on('autoplayResume', function () {
    $('.slider-controls-dots').removeClass('paused'); 
  } );

  $('.product-gallery .gallery-slide a').on('click', function (e) {
    e.preventDefault();
  });


  $(document).on('click', '[data-thumb]', function (e) {
    var id = this.getAttribute('data-thumb');
    productSwiper.slideToLoop(id);
  });


})(jQuery);